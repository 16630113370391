import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function Loading() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ widht: '100vw', height: '100vh', overflow: 'hidden' }}>
      <FontAwesomeIcon
        icon={faSpinner}
        size="4x"
        spin
        color="#8cc63e"
      />
    </div>
  );
}