/* eslint-disable complexity */
import React, { Fragment, useMemo } from 'react';
import { PDFViewer, Page, Text, View, Document, Link, StyleSheet, Font } from '@react-pdf/renderer';
import { get, sumBy } from 'lodash';

Font.register({
  family: 'Roboto', fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Regular.ttf' },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Bold.ttf', fontWeight: 700 }
  ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 20
  },
  column: {
    flexDirection: 'column',
    width: '33%'
  },
  row: {
    flexDirection: 'row'
  },
  justifyContentBetween: {
    justifyContent: 'space-between'
  },
  text: {
    color: '#191919',
    fontFamily: 'Roboto',
    fontSize: 10
  },
  h1: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 700
  },
  h2: {
    textAlign: 'center',
    fontSize: 14,
    color: '#666666'
  },
  strong: {
    color: '#000000',
    fontWeight: 700
  },
  button: {
    backgroundColor: '#8cc63e',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5
  },
  buttonText: {
    fontFamily: 'Roboto',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 700,
    textDecoration: 'none',
    textDecorationStyle: 'unset'
  },
  table: {
    flexDirection: 'column',
    borderColor: '#cccccc',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid'
  },
  tr: {
    flexDirection: 'row',
    borderColor: '#cccccc',
    borderBottomWidth: 1,
    borderStyle: 'solid'
  },
  trLast: {
    backgroundColor: '#eeeeee'
  },
  td: {
    padding: 5,
    borderColor: '#cccccc',
    borderLeftWidth: 1,
    borderStyle: 'solid'
  },
  tdRight: {
    textAlign: 'right'
  },
  tdFirst: {
    borderLeftWidth: 0
  },
  w10: {
    width: '10%'
  },
  w15: {
    width: '15%'
  },
  w20: {
    width: '20%'
  },
  w30: {
    width: '30%'
  },
  w50: {
    width: '50%'
  },
  w100: {
    width: '100%'
  },
  mb5: {
    marginBottom: 5
  },
  mb10: {
    marginBottom: 10
  },
  mb15: {
    marginBottom: 15
  },
  mb20: {
    marginBottom: 20
  },
  mb30: {
    marginBottom: 20
  },
  mb40: {
    marginBottom: 40
  }
});

function money(amount) {
  return (amount / 100).toFixed(2);
}

export default function QuotePdf({ data, elements }) {
  const currency = get(get(data, 'line_items', []).find(item => get(item, 'price.currency')), 'price.currency', '').toUpperCase();
  const interval = get(get(data, 'line_items', []).find(item => get(item, 'price.recurring.interval')), 'price.recurring.interval', null);

  const localeName = (item, locale) => item.metadata[`${locale}-name`] || item.name;

  const items = useMemo(() => data && data.line_items ?
    data.line_items.map(({ product, price, tax_rates, quantity }) => {
      let nettoPrice = price.billing_scheme === 'tiered' ? (price.tiers.find(({ up_to }) => quantity <= up_to || up_to === null).unit_amount * quantity) : price.unit_amount * quantity;
      let discount = data.coupon ? (data.coupon.percent_off * nettoPrice / 100) : 0;
      let discountedPrice = nettoPrice - discount;
      let tax = discountedPrice * tax_rates.percentage / 100;
      return {
        name: localeName(product, data.language.locale),
        quantity,
        nettoPrice,
        discountPercent: data.coupon ? data.coupon.percent_off : 0,
        discount,
        discountedPrice,
        taxRate: tax_rates.percentage,
        tax,
        bruttoPrice: discountedPrice + tax
      };
    }) : []
  , [data]);

  return (
    <PDFViewer style={{ width: '100vw', height: '100vh' }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.mb20}>
            <Text style={styles.text}>
              {`STKTK sp. z o. o.
                  Stanisława Sulimy 1/226
                  82-300 Elbląg
                  Poland
                  VAT: PL5783121448`}
            </Text>
          </View>
          <View style={styles.mb10}>
            <Text style={[styles.text, styles.h1]}>
              {elements.labelHeading}
            </Text>
            <Text style={[styles.text, styles.h2]}>
              {data.number}
            </Text>
          </View>
          <View style={[styles.row, styles.justifyContentBetween, styles.mb15]}>
            <View style={styles.column}>
              <Text style={[styles.text, styles.mb5]}>
                {`${elements.labelPreparedFor}:`}
              </Text>
              {data.customer.name && (<Text style={styles.text}>{data.customer.name}</Text>)}
              {data.customer.address && (
                <Fragment>
                  {data.customer.address.line1 && (<Text style={styles.text}>{data.customer.address.line1}</Text>)}
                  {data.customer.address.line2 && (<Text style={styles.text}>{data.customer.address.line2}</Text>)}
                  {data.customer.address.city && (<Text style={styles.text}>{data.customer.address.city}</Text>)}
                  {data.customer.address.state && (<Text style={styles.text}>{data.customer.address.state}</Text>)}
                  {data.customer.address.country && (<Text style={styles.text}>{data.customer.address.country}</Text>)}
                  {data.customer.address.postal_code && (<Text style={styles.text}>{data.customer.address.postal_code}</Text>)}
                </Fragment>
              )}
              {data.customer.email && (<Text style={styles.text}>{data.customer.email}</Text>)}
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.mb5, { textAlign: 'center' }]}>
                {`${elements.labelCreatedAt}: ${new Date(data.created_at).toLocaleDateString()}`}
              </Text>
              {data.expire_at && (
                <Text style={[styles.text, styles.strong, styles.mb5, { textAlign: 'center' }]}>
                  {`${elements.labelExpireAt}: ${new Date(data.expire_at).toLocaleDateString()}`}
                </Text>
              )}
              {data.coupon && (
                <Fragment>
                  <Text style={[styles.text, { textAlign: 'center' }]}>
                    {`${elements.labelDiscount}:`}
                  </Text>
                  <Text style={[styles.text, styles.strong, { textAlign: 'center' }]}>
                    {localeName(data.coupon, data.language.locale)}
                  </Text>
                </Fragment>
              )}
            </View>
            <View style={styles.column}>
              <Text style={[styles.text, styles.mb5, { textAlign: 'right' }]}>
                {`${elements.labelCreatedBy}:`}
              </Text>
              <Text style={[styles.text, { textAlign: 'right' }]}>
                {`${data.creator.firstName} ${data.creator.lastName}
                  ${data.creator.email}`}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tr}>
              <View style={[styles.td, data.coupon ? styles.w20 : styles.w30, styles.tdFirst]}>
                <Text style={[styles.text, styles.strong]}>{elements.tableItemName}</Text>
              </View>
              <View style={[styles.td, styles.w10]}>
                <Text style={[styles.text, styles.strong]}>{elements.tableQuantity}</Text>
              </View>
              <View style={[styles.td, data.coupon ? styles.w10 : styles.w20]}>
                <Text style={[styles.text, styles.strong]}>{`${elements.tableAmount} (${currency})`}</Text>
              </View>
              {data.coupon && (
                <Fragment>
                  <View style={[styles.td, styles.w10]}>
                    <Text style={[styles.text, styles.strong]}>{`${elements.tableDiscount} (%)`}</Text>
                  </View>
                  <View style={[styles.td, styles.w10]}>
                    <Text style={[styles.text, styles.strong]}>{`${elements.tableDiscount} (${currency})`}</Text>
                  </View>
                  <View style={[styles.td, styles.w10]}>
                    <Text style={[styles.text, styles.strong]}>{`${elements.tableAfterDiscount} (${currency})`}</Text>
                  </View>
                </Fragment>
              )}
              <View style={[styles.td, styles.w10]}>
                <Text style={[styles.text, styles.strong]}>{`${elements.tableTax} (%)`}</Text>
              </View>
              <View style={[styles.td, styles.w10]}>
                <Text style={[styles.text, styles.strong]}>{`${elements.tableTax} (${currency})`}</Text>
              </View>
              <View style={[styles.td, data.coupon ? styles.w10 : styles.w20]}>
                <Text style={[styles.text, styles.strong]}>{`${elements.tableAfterTax} (${currency})`}</Text>
              </View>
            </View>
            {items.map(({ name, quantity, nettoPrice, discountPercent, discount, discountedPrice, taxRate, tax, bruttoPrice }) => (
              <View key={name} style={styles.tr}>
                <View style={[styles.td, data.coupon ? styles.w20 : styles.w30, styles.tdFirst]}>
                  <Text style={[styles.text]}>{name}</Text>
                </View>
                <View style={[styles.td, styles.tdRight, styles.w10]}>
                  <Text style={[styles.text]}>{quantity}</Text>
                </View>
                <View style={[styles.td, styles.tdRight, data.coupon ? styles.w10 : styles.w20]}>
                  <Text style={[styles.text]}>{money(nettoPrice)}</Text>
                </View>
                {
                  data.coupon && (
                    <Fragment>
                      <View style={[styles.td, styles.tdRight, styles.w10]}>
                        <Text style={styles.text}>{discountPercent}</Text>
                      </View>
                      <View style={[styles.td, styles.tdRight, styles.w10]}>
                        <Text style={styles.text}>{money(discount)}</Text>
                      </View>
                      <View style={[styles.td, styles.tdRight, styles.w10]}>
                        <Text style={styles.text}>{money(discountedPrice)}</Text>
                      </View>
                    </Fragment>
                  )
                }
                <View style={[styles.td, styles.tdRight, styles.w10]} >
                  <Text style={[styles.text]}>{taxRate}</Text>
                </View>
                <View style={[styles.td, styles.tdRight, styles.w10]}>
                  <Text style={[styles.text]}>{money(tax)}</Text>
                </View>
                <View style={[styles.td, styles.tdRight, data.coupon ? styles.w10 : styles.w20]}>
                  <Text style={[styles.text]}>{money(bruttoPrice)}</Text>
                </View>
              </View>
            ))}
            <View style={[styles.tr, styles.trLast]}>
              <View style={[styles.td, data.coupon ? styles.w20 : styles.w30, styles.tdFirst]}>
                <Text style={[styles.text, styles.strong]}>{elements.tableSum}</Text>
              </View>
              <View style={[styles.td, styles.w10]} />
              <View style={[styles.td, styles.tdRight, data.coupon ? styles.w10 : styles.w20]}>
                <Text style={[styles.text, styles.strong]}>{money(sumBy(items, 'nettoPrice'))}</Text>
              </View>
              {
                data.coupon && (
                  <Fragment>
                    <View style={[styles.td, styles.w10]} />
                    <View style={[styles.td, styles.tdRight, styles.w10]}>
                      <Text style={[styles.text, styles.strong]}>{money(sumBy(items, 'discount'))}</Text>
                    </View>
                    <View style={[styles.td, styles.tdRight, styles.w10]}>
                      <Text style={[styles.text, styles.strong]}>{money(sumBy(items, 'discountedPrice'))}</Text>
                    </View>
                  </Fragment>
                )
              }
              <View style={[styles.td, styles.w10]} />
              <View style={[styles.td, styles.tdRight, styles.w10]}>
                <Text style={[styles.text, styles.strong]}>{money(sumBy(items, 'tax'))}</Text>
              </View>
              <View style={[styles.td, styles.tdRight, data.coupon ? styles.w10 : styles.w20]}>
                <Text style={[styles.text, styles.strong]}>{money(sumBy(items, 'bruttoPrice'))}</Text>
              </View>
            </View>
          </View>
          <View style={{ marginTop: 5 }}>
            <Text style={styles.text}>
              {elements[`${interval}IntervalText`]}
            </Text>
          </View>
          <View style={[styles.row, styles.w100, { justifyContent: 'center', marginTop: 30 }]}>
            <Link src={`${data.location.origin}${data.location.pathname}${data.location.pathname.charAt(data.location.pathname.length - 1) === '/' ? '' : '/'}?id=${data.quoteId}&payment=start`}>
              <View style={styles.button}>
                <Text style={styles.buttonText}>
                  {elements.buttonPayNow}
                </Text>
              </View>
            </Link>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}