/* eslint-disable complexity */
import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import { get, sumBy } from 'lodash';
import { css } from '@emotion/react';
import Footer from '@components/Footer';
import QuotePdf from '@components/QuotePdf';
import Loading from '@components/ui/Loading';
import Page, { getPageElements } from '@components/Page';
import axios from 'axios';

const ClientSideQuoteView = React.lazy(() => import("../components/QuoteView") )

export default function QuoteTemplate({ location, data: { page }, pageContext: { language, refs, layouts, configuration }}) {
  const elements = getPageElements(page);
  const isSSR = typeof window === "undefined";
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const quoteId = params.get('id');
    const payment = params.get('payment');


    if (!quoteId || quoteId.length === 0)
      setTimeout(() => {
        setError('noQuoteId');
      }, 2000);
    else
      fetch(`https://n8n.nospoilers.ai/webhook/a3ec336c-0b24-4baa-9c30-257c7791cb9b?id=${quoteId}`)
        .then(response => response.json())
        .then(data => {
          if (data.message)
            return setError('noQuote');

          if (payment !== 'start')
            return setData(Object.assign({}, data, { quoteId, location, language }));

          //FIXME: support other locales
          axios.post(
            'https://n8n.nospoilers.ai/webhook/b678a90a-ba56-675a-5b23-7a3a57a45a87',
            JSON.stringify(Object.assign({
              cancel_url: `${location.origin}${location.pathname}?id=${quoteId}&payment=cancel`,
              success_url: `${location.origin}/thank-you/?type=payment`,
              mode: 'subscription',
              customer: data?.customer?.id,
              line_items: data.line_items.map(({ price, quantity, tax_rates }) => ({
                price: price.id,
                quantity,
                tax_rates: [tax_rates.id],
                adjustable_quantity: {
                  enabled: false
                }
              })),
              locale: language.locale.split('_')[0],
            }, data.coupon ? {
              discounts: [{ coupon: data.coupon.id }]
            } : {})),
            { headers: { 'Content-Type': 'text/plain' } }
          )
            .then((response) => {
              if(response?.data?.url)
                window.location.replace(response.data.url);
            })
            .catch((error) => {
              console.error(error);
            });
        });
  }, []);

  return (
    <Page>
      {!isSSR && (
        <React.Suspense fallback={<Loading />}>
          <ClientSideQuoteView data={data} error={error} elements={elements} />
        </React.Suspense>
      )}
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;